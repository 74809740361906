import React, { useState, Fragment } from "react";
import { Note, Student } from "../students/student";

import { Menu, Item, useContextMenu } from "react-contexify";

import "react-contexify/dist/ReactContexify.css";
import { Subject } from "./class";
import DeleteModal from "../common/delete";
import "./style.scss";
import ApiUrls from "../../common/urls";
import {
  createDownloadLink,
  getFileHeaders,
  getHeadersPdf,
} from "../../common/utils";

interface ContentStudentProps {
  students: Student[] | undefined;
  subjects: Subject[] | undefined;
  classeId: number;
  lock: (classeId: number, order: number) => {};
  updateNote: (
    studentId: number,
    id: number,
    value: number,
    ready: boolean
  ) => {};
  filterSubjectId?: number | undefined;
  deleteNote: (classeId: number, order: number) => {};
  editWork: (order: number) => void;
  yearSettingLocked: boolean;
  yearSetting: number;
}

const Labels = {
  LastName: "Nom",
  FirstName: "Prénom",
  NoStudent: "Aucun élève dans cette classe",
  Locked: "Verrouiller/Déverrouiller",
  Delete: "Supprimer",
  Edit: "Modifier",
  Moy: "Moy",
};

const MENU_ID = "menu-id";
let lastTapTime = 0;
const ContentStudent = ({
  students,
  classeId,
  lock,
  updateNote,
  filterSubjectId,
  deleteNote,
  subjects,
  editWork,
  yearSettingLocked,
  yearSetting,
}: ContentStudentProps) => {
  const { show } = useContextMenu({ id: MENU_ID });
  const [currentOrder, setCurrentOrder] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState<string | undefined>(undefined);
  let touchTimer:any = null;

  const onSelectDate = (date: string) => {
    setCurrentDate(currentDate === date ? undefined : date);
  };

  const displayMenu = (e: any, index: number) => {
    setCurrentOrder(index);
    show({ event: e, props: { key: "value" } });
  };

  const handleTouchStart = (e:any, index: number) => {
    touchTimer = setTimeout(() => {
      displayMenu(e,index)
    }, 500); // Long press for 500ms
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimer);
  };

  const canDelete = (order: number): boolean => {
    if (students && students.length > 0) {
      const notes = [...students[0].notes];
      const note = notes.length > 0 ? notes[order] : undefined;
      if (note) {
        return !note.isBlocked;
      }
    }

    return true;
  };

  const getNotes = (s: Student) => {
    let notes: JSX.Element[] = [];

    let originalNotes = [...s.notes];
    if (filterSubjectId !== undefined && filterSubjectId > -1) {
      originalNotes = s.notes.filter((n) => n.subjectId === filterSubjectId);
    }
    if (currentDate !== undefined) {
      originalNotes = originalNotes.filter((o) => o.dateLabel === currentDate);
    }
    notes = originalNotes.map((n: Note) => {
      let classes = "";
      let max = n.scale !== undefined ? n.scale + n.bonus : 0;
      const value = n.value === -1 ? "" : n.value;
      classes = n.value < 0 ? "red" : "";
      if (n.isAbsent) {
        classes = "purple";
      }
      if (n.scale && n.value < n.scale / 2) {
        classes = `${classes} content-error`;
      }

      return (
        <td
          className="whitespace-nowrap
           text-center text-sm font-medium text-gray-900 
           sm:pl-0"
          key={n.id}
        >
          <input
            type="number"
            min={-1}
            step="0.1"
            max={max}
            pattern="[0-9]+([\.][0-9]+)?"
            className={`${classes} w-[3.5rem] h-5 text-center`}
            disabled={n.isBlocked || yearSettingLocked}
            value={value}
            onBlur={(e) =>
              e.currentTarget.value !== ""
                ? updateNote(
                    s.id,
                    n.id,
                    parseFloat(e.currentTarget.value),
                    true
                  )
                : updateNote(s.id, n.id, -1, true)
            }
            onChange={(e) =>
              e.currentTarget.value !== ""
                ? updateNote(
                    s.id,
                    n.id,
                    parseFloat(e.currentTarget.value),
                    false
                  )
                : updateNote(s.id, n.id, -1, false)
            }
          />
        </td>
      );
    });

    return notes;
  };

  const getColumns = () => {
    let columns: JSX.Element[] = [];
    if (!students) {
      return columns;
    }
    const copies = [...students];
    copies.sort((a, b) => b.notes.length - a.notes.length);

    const originalStudent = copies.find((s) => s.notes.length > 0);

    if (students && originalStudent) {
      let originalNotes = [...originalStudent.notes];
      if (filterSubjectId !== undefined && filterSubjectId > -1) {
        originalNotes = originalStudent.notes.filter(
          (n) => n.subjectId === filterSubjectId
        );
      }
      if (currentDate !== undefined) {
        originalNotes = originalNotes.filter(
          (o) => o.dateLabel === currentDate
        );
      }

      columns = originalNotes.map((n: Note, i: number) => {
        const info = `${n.scale} (${n.coefficient})`;

        const subject = subjects
          ? subjects.find((s: Subject) => s.id === n.subjectId)
          : undefined;
        const color = subject ? subject.color : "";
        const penColor = subject ? subject.penColor : "";
        const name = subject && subject.alias ? subject.alias : n.subject;

        const header = `${n.dateLabel} `;
        const indexNote = originalStudent.notes.findIndex(
          (note) => note.id === n.id
        );
        const title = `${n.dateLabel}\n${
          n.comment ? n.comment : ""
        }\nNoté sur : ${n.scale}\nCoef : ${n.coefficient}\nBonus : ${n.bonus}`;
        return (
          <th
            scope="col"
            className="whitespace-nowrap text-center text-sm font-bold text-gray-900 sm:pl-0"
          >
            <div
              className=""
              key={n.id}
              style={{ backgroundColor: color, color: penColor }}
              title={title}
            >
              <div
                className="border-red-400"
                onClick={(e) => onSelectDate( n.dateLabel)}
                onTouchStart={e => handleTouchStart(e,indexNote)}
                onTouchEnd={e => handleTouchEnd()}
               // onTouchEnd={(e) => displayMenu(e, indexNote)}
              // onDoubleClick={(e)=>   displayMenu(e, indexNote)}
                onContextMenu={(e) => displayMenu(e, indexNote)}
              >
                <div>{header}</div>
                <div className="header-scale">{`${n.scale}`}</div>
                <div className="header-scale">{`(${n.coefficient})`} </div>
              </div>
            </div>
          </th>
        );
      });
    }

    return columns;
  };

  const onLock = () => {
    lock(classeId, currentOrder);
  };

  const onDelete = () => {
    setShowDelete(true);
  };

  const onEditWork = () => {
    editWork(currentOrder);
  };

  const downloadNotes = async (id: number) => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/export-notes/${id}/${yearSetting}`;

    setIsLoading(true);
    const response = await fetch(url, {
      method: "GET",
      headers: getFileHeaders(),
    });

    const blob = await response.blob();
    createDownloadLink(blob, "notes.csv");
    setIsLoading(false);
  };

  const getAverages = (notes?: Note[]) => {
    if (!notes) {
      return [];
    }
    let originalNotes = [...notes];
    if (filterSubjectId !== undefined && filterSubjectId > -1) {
      originalNotes = notes.filter((n) => n.subjectId === filterSubjectId);
    }
    if (currentDate !== undefined) {
      originalNotes = originalNotes.filter((o) => o.dateLabel === currentDate);
    }
    const result = originalNotes.map((n: Note) => {
      const moyenne = n.classeAverage > -1 ? n.classeAverage : undefined;
      return (
        <td
          key={n.id}
          className="whitespace-nowrap
                                      text-center text-sm font-medium text-gray-900 
                                     
                                      sm:pl-0"
        >
          {moyenne}
        </td>
      );
    });
    return result;
  };

  const getClassSubjectAverage = (notes?: Note[]) => {
    
    if (filterSubjectId === undefined || filterSubjectId < -1) {
      return "";
    }
    if (!notes) {
      return "";
    }

    const originalNotes = notes.filter((n) => n.subjectId === filterSubjectId);
    const value =
      originalNotes.length > 0
        ? originalNotes[0].classSubjectAverageValue
        : undefined;
    return value === -1 ? "" : value;
  };

  const getStudentSubjectAverage = (notes?: Note[]) => {

    const subjectAverageClass =
      filterSubjectId === undefined || filterSubjectId < -1
        ? undefined
        : notes?.find((o) => o.subjectId === filterSubjectId);
    const subjectAverageClassValue =
      subjectAverageClass !== undefined && subjectAverageClass.subjectAverageValue != -1
        ? subjectAverageClass.subjectAverageValue
        : "";

    return subjectAverageClassValue;
  };

  return (
    <>
      <div className="flex overflow-x-auto w-full">
        {!yearSettingLocked && (
          <Fragment>
            <DeleteModal
              handleClose={() => setShowDelete(false)}
              show={showDelete}
              confirm={() => deleteNote(classeId, currentOrder)}
            />

            <div className="">
              <Menu id={MENU_ID}>
                {canDelete(currentOrder) && (
                  <Item onClick={onEditWork}>{Labels.Edit}</Item>
                )}

                <Item onClick={onLock}>{Labels.Locked}</Item>
                {canDelete(currentOrder) && (
                  <Item onClick={onDelete}>{Labels.Delete}</Item>
                )}
              </Menu>
            </div>
          </Fragment>
        )}

        <br />

        {students && students.length > 0 ? (
          <>
          
            <div className="row">
              <div className="flow-root mb-5">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-gray-300 h-24">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="whitespace-nowrap text-left text-sm font-bold text-gray-900 pl-2"
                          >
                            Nom
                            <div className="flex flex-row">
                              <div>{students.length} élève(s)</div>

                              <span className="col-md-2">
                                <button
                                  className="btn mb-2"
                                  disabled={isLoading}
                                  title={"Télécharger les notes"}
                                  onClick={() => downloadNotes(classeId)}
                                >
                                  <i
                                    title="Télécharger les notes"
                                    className={
                                      isLoading
                                        ? "icon-spinner"
                                        : "icon-download-alt"
                                    }
                                  ></i>
                                </button>
                              </span>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap text-center text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            <div
                              className="bold"
                            
                            >
                              {Labels.Moy}
                            </div>
                          </th>
                          {getColumns()}
                        </tr>
                      </thead>
                      <tbody>
                        {students.map((s) => {
                          return (
                            <tr key={s.id} className="odd:bg-gray-200">
                              <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 pl-2">
                                <div className="">{`${s.lastName} ${s.firstName}`}</div>
                              </td>
                              <td
                                className="whitespace-nowrap
                                      text-left text-sm font-medium text-gray-900                                       
                                      "
                              >
                                <input type="text" 
                                disabled={true}
                                readOnly={true}
                                value={getStudentSubjectAverage(s.notes)} className="w-[3rem] h-5" />
                              </td>
                              {getNotes(s)}
                            </tr>
                          );
                        })}

                        <tr>&nbsp;</tr>
                        <tr className="whitespace-nowrap text-left text-sm font-medium text-gray-900 sm:pl-0">
                          <td
                            className="whitespace-nowrap
                                      text-left text-sm font-medium text-gray-900                                       
                                      pl-2"
                          >
                            Moyenne classe
                          </td >
                          <td className="whitespace-nowrap
                                      text-center text-sm font-medium text-gray-900                                       
                                      ">{getClassSubjectAverage(students[0].notes)}</td>

                          {getAverages(
                            students.find((s) => s.notes.length > 0)?.notes
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>{Labels.NoStudent}</div>
        )}
      </div>
    </>
  );
};

export default ContentStudent;
