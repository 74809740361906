import React, { Children, FC, useEffect, useState } from "react";
import * as RoutesUrls from "../routing/routes";
import "./header.scss";
import logo from "../layout/logo.png";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import {
  ChevronDownIcon,
  Cog6ToothIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LocalStorageKey from "../common/localStorage";
import {
  classNames,
  getHeaders,
  getUserName,
  isAdmin,
  isAuthenticated,
} from "../common/utils";
import { useNavigate } from "react-router";
import ApiUrls from "../common/urls";
import { fetchApi } from "../common/api";
import moment from "moment";
import Resource from "../views/settings/resource";
import { useToasts } from "react-toast-notifications";

interface NavigationItem {
  name: string;
  href: string;
  id: number;
  display: boolean;
}

const shouldBeDisplay = () => isAdmin() && isAuthenticated();

const navigation: NavigationItem[] = [
  {
    name: `Import données`,
    href: `${RoutesUrls.IMPORT}`,
    id: 1,
    display: shouldBeDisplay(),
  },
  {
    name: "Gestion année",
    href: `${RoutesUrls.YEAR_SETTING}`,
    id: 2,
    display: shouldBeDisplay(),
  },
  {
    name: "Niveaux",
    href: `${RoutesUrls.LEVELS}`,
    id: 3,
    display: shouldBeDisplay(),
  },
  {
    name: "Pôles",
    href: `${RoutesUrls.POLES}`,
    id: 4,
    display: shouldBeDisplay(),
  },
  {
    name: "Matières",
    href: `${RoutesUrls.MATIERES}`,
    id: 5,
    display: shouldBeDisplay(),
  },
  {
    name: "Classes",
    href: `${RoutesUrls.CLASSES}`,
    id: 6,
    display: shouldBeDisplay(),
  },
  {
    name: "Absences",
    href: `${RoutesUrls.GESTION_ABSENCE}`,
    id: 7,
    display: shouldBeDisplay(),
  },
  {
    name: "Elèves",
    href: `${RoutesUrls.STUDENTS}`,
    id: 8,
    display: shouldBeDisplay(),
  },
  {
    name: "Utilisateurs",
    href: `${RoutesUrls.UTILISATEURS}`,
    id: 9,
    display: shouldBeDisplay(),
  },
];
const userNavigation = [
  {
    name: "Se déconnecter",
    display: isAuthenticated(),
    href: "#",
    onClick: () => {
      localStorage.removeItem(LocalStorageKey.Auth);
      window.location.href = `${RoutesUrls.LOGIN}`;
    },
  },
];

const Labels = {
  Error: "Erreur avec la connexion",
};

type StudentCommentBody = {
  description: string;
  studentId: number;
  poleId: number;
  yearSetting: number | null;
  id: number | null | undefined;
  isGlobal: boolean;
};
type StudentPerson = {
  id: number;
  note: number;
  noteId: number;
};

const MainLayout: FC<React.PropsWithChildren> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentLink, setCurrentLink] = useState(-1);
  const [localLogo, setlocalLogo] = useState<Resource>();

  const history = useNavigate();

  const [events, setEvents] = useState(["click", "scroll"]);
  const { addToast } = useToasts();

  const fetchResource = async (): Promise<Resource | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/resources/4`;
    return await fetchApi<Resource>(url);
  };
  const pingNetwork = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Network}`;

    try {
      await fetch(url);
    } catch (e: any) {
      addToast(Labels.Error, { appearance: "error", autoDismiss: true });
    }
  };

  const fetchTimeOut = async (): Promise<number | null> => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Settings}/time-out`;
    return await fetchApi<number>(url);
  };

  let startTime: NodeJS.Timeout;

  const setTimer = () => {
    const timeStamp = moment();
    sessionStorage.setItem("lastTimeStamp", timeStamp.toLocaleString());
  };

  const beginInterval = (timeOut: number) => {
    startTime = setInterval(() => {
      const stamp = sessionStorage.getItem("lastTimeStamp");
      const timeStamp = moment();
      const lastStamp = moment(stamp);

      const minus = timeStamp.subtract(timeOut, "minute");

      if (minus > lastStamp) {
        localStorage.removeItem(LocalStorageKey.Auth);
        window.location.href = "/login";
        sessionStorage.removeItem("lastTimeStamp");
      }
    }, 1000 * 60);
  };

  const onLinkClick = (item: NavigationItem) => {
    history(item.href);
    setCurrentLink(item.id);
  };

  const onImgClick = () => {
    if (isAuthenticated()) {
      history("/classes");
    } else {
      history("/login");
    }
  };

  const insertNotes = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Note}`;
    const items = localStorage.getItem(LocalStorageKey.Notes);

    if (items !== null) {
      const notes: StudentPerson[] = JSON.parse(items);

      if (notes.length > 0) {
        notes
          .filter((n) => n.noteId > 0)
          .forEach(async (n) => {
            const body = {
              id: n.noteId,
              value: n.note,
            };
            try {
              await fetch(url, {
                method: "Put",
                headers: getHeaders(),
                body: JSON.stringify(body),
              });
              const copies = [...notes];
              const index = copies.findIndex((c) => c.noteId === n.noteId);
              copies.splice(index, 1);

              localStorage.setItem(
                LocalStorageKey.Notes,
                JSON.stringify(copies)
              );
            } catch (e: any) {}
          });
      }
    }
  };

  const insertComments = async () => {
    const url = `${process.env.REACT_APP_API}/${ApiUrls.Classes}/comment`;
    const items = localStorage.getItem(LocalStorageKey.Comments);

    if (items !== null) {
      const comments: StudentCommentBody[] = JSON.parse(items);

      if (comments.length > 0) {
        comments.forEach(async (n) => {
          const body = n;
          try {
            await fetch(url, {
              method: "Put",
              headers: getHeaders(),
              body: JSON.stringify(body),
            });
            const copies = [...comments];
            const index = copies.findIndex(
              (c) =>
                c.id === n.id ||
                (c.poleId === n.poleId && c.studentId === n.studentId)
            );
            copies.splice(index, 1);

            localStorage.setItem(
              LocalStorageKey.Comments,
              JSON.stringify(copies)
            );
          } catch (e: any) {}
        });
      }
    }
  };

  useEffect(() => {
    fetchResource().then((response) => {
      if (response) {
        setlocalLogo(response);
        const appleIcon = document.getElementById("apple-touch-icon");
        const icon = document.getElementById("icon");
        appleIcon?.setAttribute(
          "href",
          `data:image/png;base64,${response.content}`
        );
        icon?.setAttribute("href", `data:image/png;base64,${response.content}`);
      }
    });
    const interval = 5 * 1000;

    const pingNetworkInterval = setInterval(pingNetwork, interval);
    const pingUpdateNotes = setInterval(insertNotes, interval);
    const pingUpdateComments = setInterval(insertComments, interval);

    fetchTimeOut().then((response) => {
      events.forEach((event) => {
        window.addEventListener(event, setTimer);
      });
      if (response) {
        beginInterval(response);
      }
    });

    return () => {
      clearInterval(startTime);
      sessionStorage.removeItem("lastTimeStamp");
      clearInterval(pingNetworkInterval);
      clearInterval(pingUpdateNotes);
      clearInterval(pingUpdateComments);
    };
  }, []);

  const getLogo = () =>
    localLogo ? `data:image/png;base64,${localLogo.content}` : "";

  return (
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-blue-500">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  alt="Notes"
                  src={getLogo()}
                  className="h-10 w-20"
                  onClick={onImgClick}
                />
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {navigation
                    .filter((n) => n.display)
                    .map((item) => (
                      <a
                        key={item.name}
                        href="#"
                        onClick={() => onLinkClick(item)}
                        className={classNames(
                          currentLink === item.id
                            ? "bg-blue-500 text-white"
                            : "text-white hover:bg-blue-300 hover:bg-opacity-75",
                          "rounded-md px-3 py-2 text-sm font-medium",
                          "hover:no-underline"
                        )}
                      >
                        {item.name}
                      </a>
                    ))}
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="relative rounded-full bg-blue-500 p-1 text-purple-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-purple-600"
                ></button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <MenuButton
                      className="relative flex max-w-xs items-center
                     bg-blue-500 text-sm
                     text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-purple-600"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {getUserName()}
                        </div>
                      </div>
                      <div className="h-8 w-8 rounded-full"></div>
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md
                     bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userNavigation
                      .filter((u) => u.display)
                      .map((item) => (
                        <MenuItem key={item.name}>
                          <a
                            onClick={item.onClick}
                            href={item.href}
                            className=" block px-4 py-2 text-sm text-purple-700 hover:no-underline hover:text-purple-700 "
                          >
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
            {isAuthenticated() && (
              <>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-blue-500 p-2 text-purple-200 hover:bg-blue-300 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-purple-600">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon
                      aria-hidden="true"
                      className="block h-6 w-6 group-data-[open]:hidden"
                    />
                    <XMarkIcon
                      aria-hidden="true"
                      className="hidden h-6 w-6 group-data-[open]:block"
                    />
                  </DisclosureButton>
                </div>
              </>
            )}
          </div>
        </div>

        {isAuthenticated() && (
          <>
            <DisclosurePanel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation
                  .filter((n) => n.display)
                  .map((item) => (
                    <DisclosureButton
                      key={item.name}
                      as="a"
                      href="#"
                      onClick={() => onLinkClick(item)}
                      className={classNames(
                        currentLink === item.id
                          ? "bg-blue-500 text-white"
                          : "text-white hover:bg-blue-300 hover:bg-opacity-75",
                        "block rounded-md px-3 py-2 text-base font-medium",
                        "hover:no-underline"
                      )}
                    >
                      {item.name}
                    </DisclosureButton>
                  ))}
              </div>
              <div className="border-t border-purple-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {getUserName()}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-blue-500 p-1 text-purple-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-purple-600"
                  ></button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {isAuthenticated() && <></>}
                  {userNavigation
                    .filter((u) => u.display)
                    .map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        onClick={item.onClick}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-blue-300 hover:bg-opacity-75"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                </div>
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>

      <main>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>
    </div>
  );
};

export default MainLayout;
